import { HttpClient } from "@angular/common/http";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { faUnlock, faEnvelope, faTennisBall, faEnvelopesBulk } from "@fortawesome/pro-solid-svg-icons";
import { filter } from "rxjs/operators";

import { ActivationEnum } from "app/core/enums/generated/ActivationEnum";
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { SecurityService } from "app/core/security/security.service";
import { SystemMessage, SystemMessageService } from "app/core/system-message/system-message-service";
import { DynamicFormService } from "app/shared/form-elements/components/dynamic-form/dynamic-form.service";
import { EditUserVm } from "app/shared/generated/Administration/Models/Users/EditUserVm";
import { DynamicFormFieldVm } from "app/shared/generated/Models/DynamicFormFieldVm";
import { pcgSettings } from "app/shared/generated/pcg-settings";
import { NavigationService } from "app/shared/navigation/navigation.service";
import { ActivationService } from "app/shared/services/activation.service";

@Component({
	selector: "pcg-edit-user",
	templateUrl: "./edit-user.component.html",
	styleUrls: ["./edit-user.component.scss"]
})
export class EditUserComponent implements OnInit {

	@ViewChild("pinModal", { static: true }) pinModal: TemplateRef<any>;

	formGroup = EditUserVm.Form;
	model = EditUserVm.GetModel(this.formGroup);
	fields: DynamicFormFieldVm[];
	businessArea: BusinessAreaEnum;

	customFieldsCount: number;
	id: number;

	activeTab: string;
	mode: string;
	pin: string;
	strError = "";

	isLockedOut = false;
	hasTabParam = false;
	canEdit = false;
	canEditFacilities = false;
	canCreate = false;
	canViewLoginHistory = false;
	canUnlock = false;
	canResetPassword = false;
	canDeactivate = false;
	canViewSecurity = false;
	canSendWelcomeEmail = false;
	canViewReports = false;
	canSeePasswordButtons = false;
	isCurrentUser = false;
	canManageUsers = false;
	canViewAuditHistory = false;
	isSysAdmin = false;
	inactivityTimeoutFg = false;
	isInternalBphpUserFg = false;
	mustActivateMfaAndInactivity = false;
	isError = false;
	showValidateEmail = false;
	useDifferentEmail = false;

	envMode = pcgSettings.mode;

	faIconName = { faUnlock, faEnvelope, faTennisBall, faEnvelopesBulk };

	phoneValidation = { pattern: "Invalid phone number" };

	constructor(
		private activatedRoute: ActivatedRoute
		, private httpClient: HttpClient
		, public router: Router
		, private ms: SystemMessageService
		, private nav: NavigationService
		, title: Title
		, private sec: SecurityService
		, private dynForm: DynamicFormService
		, private activationService: ActivationService
	) { title.setTitle("Edit User"); }

	ngOnInit() {
		if (this.activatedRoute.snapshot.queryParamMap.get("businessArea")) {
			this.businessArea = BusinessAreaEnum[ this.activatedRoute.snapshot.queryParamMap.get("businessArea").toString() ];
		}
		this.addSecuritySubscription();
		this.addRouteSubscription();
		this.disableFormControlsBasedOnPermission();
		// Set mustActivateMfaAndInactivity from queryParams
		this.mustActivateMfaAndInactivity = this.activatedRoute.snapshot.queryParams["fromCheckCanDecrypt"];
		// Remove fromCheckCanDecrypt from queryParams
		this.router.navigate(
			[]
			, {
				queryParams: { fromCheckCanDecrypt: null }
				, queryParamsHandling: "merge"
			}
		);
	}

	// #region Security
	/** Update the local dynamic security object when the security updates */
	addSecuritySubscription() {
		this.isSysAdmin = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.SystemAdmin ]
		);
		this.sec.setControlModuleAccess(
			this.formGroup.controls["isActive"]
			, [ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Pharmacist)
		);
		this.sec.setControlModuleAccess(
			this.formGroup.controls["inventoryIds"]
			, [ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Pharmacist)
		);
		this.canEdit = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.canCreate = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		);
		this.canManageUsers = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Pharmacist)
		);
		this.canViewLoginHistory = this.sec.hasModuleAccess(
			SecurityService.anyArea()
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canUnlock = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) 
		|| this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula 
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canResetPassword = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) 
		|| this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canSendWelcomeEmail = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) || this.sec.hasModuleAccess(
			[
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canViewReports = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.SystemAdmin ]
		) || this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canViewSecurity = this.sec.hasModuleAccess(
			SecurityService.anyArea()
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.canDeactivate = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) || this.sec.hasModuleAccess(
			[
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canViewAuditHistory = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Manager ]
		) ||
		this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
	}
	// #endregion

	disableFormControlsBasedOnPermission() {
		if (!this.isSysAdmin) {
			this.formGroup.get("isActive").disable();
			this.formGroup.get("inventoryIds").disable();
		}
		if (this.canDeactivate) { this.formGroup.get("isActive").enable(); }
		if (
			this.businessArea !== null 
			&& this.businessArea !== undefined
		) {
			switch (this.businessArea?.toString()) {
				case "MetabolicFormula":
					this.canEditFacilities = this.sec.hasModuleAccess(
						[ BusinessAreaEnum.MetabolicFormula ]
						, [
							PermissionRole.Manager
							, ...SecurityService.setMinRole(PermissionRole.Manager)
						]
					);
					break;
				case "CI":
					this.canEditFacilities = this.sec.hasModuleAccess(
						[ BusinessAreaEnum.CI ]
						, [
							PermissionRole.Manager
							, ...SecurityService.setMinRole(PermissionRole.Manager)
						]
					);
					break;
				case "Admin":
					this.canEditFacilities = this.sec.hasModuleAccess(
						[ BusinessAreaEnum.Admin ]
						, [
							PermissionRole.Manager
							, ...SecurityService.setMinRole(PermissionRole.Manager)
						]
					);
					break;
				default:
					break;
			}
		} else {
			this.canEditFacilities = this.sec.hasModuleAccess(
				[
					BusinessAreaEnum.Admin
					, BusinessAreaEnum.MetabolicFormula
					, BusinessAreaEnum.CI
				]
				, [
					PermissionRole.Manager
					, ...SecurityService.setMinRole(PermissionRole.Manager)
				]
			);
		}
	}

	/** Subscribe to route to pick up id changes and update page accordingly */
	addRouteSubscription() {		
		this.activatedRoute.paramMap.subscribe((map) => {
			// Allow user to view page if it is either their profile
			// or they have admin access to view other people's profiles
			const user = this.sec.getUser();
			let paramId = map.get("id");
			if (paramId === "currentUser") { window.location.replace(this.router.url.replace(paramId, user.id.toString())); } 
			else { this.id = parseInt(paramId, 10); }

			if (
				(
					this.id !== user.id 
					&& !this.canEdit
				) 
				|| (
					this.id === 0 
					&& !this.canCreate
				)
			) { this.router.navigate(["/"]); }

			if (this.activatedRoute.snapshot.queryParamMap.get("tab")) {
				var newTab = this.activatedRoute.snapshot.queryParamMap.get("tab");
				this.activeTab = newTab;
				this.hasTabParam = true;
			}

			this.isCurrentUser = this.id === this.sec.getUser().id;
			this.httpClient.get(`api/Administration/Users/GetUser/${this.id}`)
				.subscribe((vm: EditUserVm) => {
					this.mode = vm.mode;
					this.fields = vm.fields;
					this.formGroup = this.dynForm.updateFormGroupFields(
						this.formGroup
						, vm.fields
					);
					this.customFieldsCount = vm.fields?.length ?? 0;
					this.formGroup.patchValue(vm);
					this.model.isActive = vm.isActive;
					this.canSeePasswordButtons = vm.isActive 
						&& !vm.isLockedOut 
						&& vm?.userId !== this.sec.getUser().id 
						&& vm?.userId !== 0;
					this.isLockedOut = vm.isLockedOut;
					this.pin = vm.userPin ?? "";
					this.inactivityTimeoutFg = vm.inactivityTimeout;
					this.isInternalBphpUserFg = vm.isInternalBphpUser;
				}
			);

			this.setSecondaryNav();
		});
	}

	/** Set the navigation that sits under the main navigation */
	setSecondaryNav() {
		if (this.sec.hasModuleAccess([ BusinessAreaEnum.Admin ], [ PermissionRole.Pharmacist ])) {
			this.nav.setOpenSecondaryMenu(["Admin", "Users", "User List"]);
		}
	}

	/** The main save event for the page */
	saveUser(msg: SystemMessage) {
		if (msg.value) {
			const model = this.formGroup.value;
			this.id = msg.model?.userId;
			this.canSeePasswordButtons = model.isActive 
				&& !model.isLockedOut 
				&& model.userId !== this.sec.getUser().id 
				&& model.userId !== 0;
			if (msg.isSuccessful) {
				this.router.navigateByUrl("/", { skipLocationChange: true })
					.then(() => { this.router.navigate([`/edit-user/${this.id}`]); });
				if (model.userId === this.sec.getUser().id) { window.location.reload(); }
			}
		}
	}

	/** Unlock user, called when the unlock svg clicked on locked user */
	unlockUser() {
		this.ms.getHttpObservable(this, `api/Administration/Users/UnlockUser/${this.id}`)
			.subscribe(() => { this.isLockedOut = false; });
	}

	/** Called on click of the reset password and welcome email buttons */
	resetPassword(isWelcomeEmail: boolean) {
		this.httpClient.post(
			"api/Account/ForgotPassword"
			, {
				email: this.formGroup.value.email
				, isWelcomeEmail
			}
		).subscribe((msg: SystemMessage) => {
			if (msg.isSuccessful) {
				this.ms.setSystemMessage(`An email has been sent to the user with a ${isWelcomeEmail ? "welcome" : "reset password"} link.`);
			} else {
				this.ms.setSystemMessage("An error occurred when attempting to send the email.", "error");
			}
		});
	}

	activateDeactivate() {
		this.activationService.activateDeactivate(
			this.id
			, ActivationEnum.User
			, this.formGroup.controls.isActive.value ? false : true
		);
	}

	validateEmail() {
		this.showValidateEmail = false;
		this.isError = false;
		this.strError = "";

		this.httpClient.post(
			`api/Administration/Users/ValidateEmail?id=${this.model.userId}`
			, {}
		).subscribe((sm: SystemMessage) => {
			if (sm.isSuccessful) {
				this.model.emailConfirmed = true;
				this.formGroup.controls.emailConfirmed.patchValue(true);
				this.ms.setSystemMessage(sm.message, "success");
			} else {
				this.showValidateEmail = true;
				this.isError = true;
				this.strError = sm.message + "\n"
					+ "Email: " + sm.model?.email + "\n"
					+ "Verdict: " + sm.model?.verdict + "\n"
					+ "Score: " + sm.model?.score + "\n"
					+ "Has Mx or a Record: " + sm.model?.checks?.domain?.has_mx_or_a_record + "\n"
					+ "Has Valid Address Syntax: " + sm.model?.checks?.domain?.has_valid_address_syntax + "\n"
					+ "Is Suspected Disposable Address: " + sm.model?.checks?.domain?.is_suspected_disposable_address + "\n"
					+ "Has Known Bounces: " + sm.model?.checks?.additional?.has_known_bounces + "\n"
					+ "Has Suspected Bounces: " + sm.model?.checks?.additional?.has_suspected_bounces;
			}
		});
	}

	overrideAndValidate() {
		this.showValidateEmail = false;
		this.isError = false;
		this.strError = "";

		this.httpClient.post(
			`api/Administration/Users/ValidateEmail?id=${this.model.userId}&overrideValidate=true`
			, {}
		).subscribe((sm: SystemMessage) => {
			if (sm.isSuccessful) {
				this.model.emailConfirmed = true;
				this.formGroup.controls.emailConfirmed.patchValue(true);
				this.ms.setSystemMessage(sm.message, "success");
			} else {
				this.isError = true;
				this.strError = sm.message;
			}
		});
	}

	bounceEmail() {
		this.isError = false;
		this.strError = "";
		this.httpClient.post(
			`api/Administration/Users/RemoveBounceEmail?id=${this.model.userId}`
			, {}
		).subscribe((sm: SystemMessage) => {
			if (sm.isSuccessful) {
				this.model.isEmailBounced = false;
				this.formGroup.controls.isEmailBounced.patchValue(false);
				this.ms.setSystemMessage(sm.message, "success");
			} else {
				this.isError = true;
				this.strError = sm.message;
			}
		});
	}

	undoSpamReport() {
		this.httpClient.get(`api/Administration/Users/RemoveSpamReport?id=${this.model.userId}`)
			.subscribe((sm: SystemMessage) => {
				if (sm.isSuccessful) {
					this.model.isEmailSpamReported = false;
					this.formGroup.controls.isEmailSpamReported.patchValue(false);
					this.ms.setSystemMessage(sm.message, "success");
				} else {
					this.isError = true;
					this.strError = sm.message;
				}
			}
		);
	}

	emailChange() {
		this.isError = false;
		this.strError = "";
		this.showValidateEmail = false;
		this.useDifferentEmail = false;

		if (this.model.email === "") { return; }

		if (this.id === 0) {
			this.httpClient.get(`api/Administration/Users/GetUserByEmailNewUser?email=${this.model.email}`)
				.subscribe((sm: SystemMessage) => {
					if (!sm.isSuccessful) {
						this.isError = true;
						if (sm.model != null) {
							this.strError = sm.message + "\n"
								+ "Email: " + sm.model?.emailError?.email + "\n"
								+ "Verdict: " + sm.model?.emailError?.verdict + "\n"
								+ "Score: " + sm.model?.emailError?.score + "\n"
								+ "Has Mx or a Record: " + sm.model?.emailError?.checks?.domain?.has_mx_or_a_record + "\n"
								+ "Has Valid Address Syntax: " + sm.model?.emailError?.checks?.domain?.has_valid_address_syntax + "\n"
								+ "Is Suspected Disposable Address: " + sm.model?.emailError?.checks?.domain?.is_suspected_disposable_address + "\n"
								+ "Has Known Bounces: " + sm.model?.emailError?.checks?.additional?.has_known_bounces + "\n"
								+ "Has Suspected Bounces: " + sm.model?.emailError?.checks?.additional?.has_suspected_bounces + "\n\n"
								+ "If this is a valid email address, finish filling out the form and click create to continue.";
						} else { this.strError = sm.message; }
					}
				});
		} else {
			this.httpClient.post(
				`api/Administration/Users/ChangeEmail`
				, {
					id: this.model.userId
					, email: this.model.email
				}
			).subscribe((sm: SystemMessage) => {
				if (!sm.isSuccessful) {
					this.isError = true;
					if (sm.value == false) {
						this.strError = sm.message;	
					} else {
						this.showValidateEmail = true;
						this.strError = sm.message + "\n"
							+ "Email: " + sm.model?.email + "\n"
							+ "Verdict: " + sm.model?.verdict + "\n"
							+ "Score: " + sm.model?.score + "\n"
							+ "Has Mx or a Record: " + sm.model?.checks?.domain?.has_mx_or_a_record + "\n"
							+ "Has Valid Address Syntax: " + sm.model?.checks?.domain?.has_valid_address_syntax + "\n"
							+ "Is Suspected Disposable Address: " + sm.model?.checks?.domain?.is_suspected_disposable_address + "\n"
							+ "Has Known Bounces: " + sm.model?.checks?.additional?.has_known_bounces + "\n"
							+ "Has Suspected Bounces: " + sm.model?.checks?.additional?.has_suspected_bounces;
					}
				} else { this.ms.setSystemMessage(sm.message, "success"); }
			});
		}
	}
}
