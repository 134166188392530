import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';

export const getIcqNav: () => NavRoute = () => {

    const user = getUser();
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.

    if (user !== null) {
		var adminSettingsNavItems: NavRoute[] = [
			{
				name: 'Sections'
				, exact: true
				, path: 'icq/admin/sections'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ICQ ]
						, permissionRoles: sec.setMinRole(p.SystemAdmin)
					}
				]
			}
			, {
				name: 'Issues'
				, exact: true
				, path: 'icq/admin/issues'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ICQ ]
						, permissionRoles: sec.setMinRole(p.SystemAdmin)
					}
				]
			}
			, {
				name: 'Questions'
				, exact: true
				, path: 'icq/admin/questions'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ICQ ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
			, {
				name: 'Surveys'
				, exact: true
				, path: 'icq/admin/surveys'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ICQ ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
			, {
				name: 'Settings'
				, exact: true
				, path: 'icq/admin/settings'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ICQ ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
		];

		if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.ICQ)) {
			adminSettingsNavItems.push({
				name: 'Error Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ICQ ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
				, exact: true
				, path: `error-report/error-report-list/${BusinessAreaEnum.ICQ}`
			});
		}



        return {
            name: 'ICQ'
            , canActivate: [ AuthGuard ]
            , moduleAccess: [
                {
					modules: [ BusinessAreaEnum.ICQ ]
					, permissionRoles: sec.setMinRole(p.User)
				}
            ]
            , children: [
                {
                    name: 'Review Queue'
                    , exact: true
                    , path: 'icq/review-queue'
                    , moduleAccess: [
                        {
                            modules: [ BusinessAreaEnum.ICQ ]
                            , permissionRoles: sec.setMinRole(p.User)
                        }
                    ]
                }
                , {
					name: 'Admin Settings'
					, href: 'icq/admin/questions'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.ICQ ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
					, children: adminSettingsNavItems
				}
            ]
        };
    }

    return { name: "" };
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
