import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';

export const getCiRepackNav: () => NavRoute = () => {
	const user = getUser();
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.

	if (user !== null) {
		var adminSettingsNavItems: NavRoute[] = [
			{
				name: 'CI Settings'
				, exact: true
				, path: 'ci-repack/admin/settings'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
			, {
				name: 'Invoicing Costs'
				, exact: true
				, path: 'ci-repack/admin/invoice-costs'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: sec.setMinRole(p.Pharmacist)
					}
				]
			}
			, {
				name: 'Plates'
				, exact: true
				, path: 'ci-repack/admin/plates'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: sec.setMinRole(p.Technician)
					}
				]
			}
			, {
				name: 'Workstations'
				, exact: true
				, path: 'ci-repack/admin/workstations'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
			, {
				name: 'Label Printers'
				, exact: true
				, path: 'ci-repack/admin/label-printers'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
			, {
				name: 'Office Printers'
				, exact: true
				, path: 'ci-repack/admin/office-printers'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
			, {
				name: 'Zebra Printers'
				, exact: true
				, path: 'ci-repack/admin/zebra-printers'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
			, {
				name: 'Boxes'
				, exact: true
				, path: 'ci-repack/admin/boxes'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.Manager ]
					}
				]
			}
			, {
				name: 'Corrected Card/Strip Reasons'
				, exact: true
				, path: 'ci-repack/admin/rework-reasons'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.Manager ]
					}
				]
			}
			, {
				name: 'Verification Settings'
				, exact: true
				, path: 'ci-repack/admin/verification-settings'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
		];

		if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.CI)) {
			adminSettingsNavItems.push({
				name: 'Error Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [ p.User ]
					}
				]
				, exact: true
				, path: `error-report/error-report-list/${BusinessAreaEnum.CI}`
			});
		}

		return {
			name: 'CI Repack'
			, canActivate: [ AuthGuard ]
			, href: 'ci-repack/dashboard'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.CI ]
					, permissionRoles: sec.setMinRole(p.User)
				}
			]
			, children: [
				{
					name: 'Dashboard'
					, exact: true
					, path: 'ci-repack/dashboard'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
				}
				, {
					name: "KPI's"
					, exact: true
					, path: 'ci-repack/kpis'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.Technician)
						}
					]
				}
				, {
					name: 'Global Orders'
					, exact: true
					, path: 'ci-repack/orders'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
				}
				, {
					name: 'Global Jobs'
					, exact: true
					, path: 'ci-repack/jobs'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
				}
				, {
					name: 'Repack Invoices'
					, exact: true
					, path: 'ci-repack/invoices'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.Manager)
						}
					]
				}
				, {
					name: 'Bottle Log'
					, exact: true
					, path: 'ci-repack/bottle-log'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.Technician)
						}
					]
				}
				, {
					name: 'Facility List'
					, exact: true
					, path: 'ci-repack/ci-facilities'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [
								p.UserAdmin
								, ...sec.setMinRole(p.Manager)
							]
						}
					]
				}
				, {
					name: 'Admin Settings'
					, href: 'ci-repack/admin/settings'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
					, children: adminSettingsNavItems
				}
			]
		};
	}

	return { name: "" };
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
