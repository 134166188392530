import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';
import { AddressInformationVm, AddressInformationVmFromFg } from '../../HEROS/Models/AddressInformationVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditAgencyVm {
	agencyId: number;
	agencyName?: string;
	agencyAbbreviation?: string;
	fein?: string;
	mmcapAnticipatedDate?: Date;
	taxStatus?: any;
	strTaxStatus?: string;
	emsStateId?: string;
	mmcapAccountNumber?: string;
	otherTaxStatus?: string;
	county?: number;
	countyName?: string;
	isMmcap?: boolean;
	orgType?: any;
	strOrgType?: string;
	operationCounty?: number;
	operationCountyName?: string;
	otherOrgType?: string;
	useAgencyInfoForShipInfo: boolean;
	address?: AddressInformationVm;
	agencySmartyAddress?: any;
	agencySmartyAddressString?: string;
	hasAgencySmarty: boolean;
	shippingInformation?: AddressInformationVm;
	shipSmartyAddress?: any;
	shipSmartyAddressString?: string;
	hasShipSmarty: boolean;
	agencyHeadName?: string;
	agencyHeadTitle?: string;
	agencyHeadEmail?: string;
	agencyHeadPhoneNumber?: string;
	agencyHasApplications: boolean;
	disableAgency: boolean;
	hasInitialApproval: boolean;
	friendlyId?: string;
	primaryName?: string;
	primaryTitle?: string;
	primaryEmail?: string;
	primaryPhone?: string;
	primaryUserId?: number;
	secondaryName?: string;
	secondaryTitle?: string;
	secondaryEmail?: string;
	secondaryPhone?: string;
	secondaryUserId?: number;
	tertiaryName?: string;
	tertiaryTitle?: string;
	tertiaryEmail?: string;
	tertiaryPhone?: string;
	tertiaryUserId?: number;
	canEditHistoric?: boolean;
	isActive: boolean;
	mmcapVerified: boolean;
	isDeactivateConfirmed?: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'agencyId': new UntypedFormControl(0, [...(validators['agencyId'] ?? []), ...[Validators.required]]),
			'agencyName': new UntypedFormControl(null, [...(validators['agencyName'] ?? []), ...[Validators.required, Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'agencyAbbreviation': new UntypedFormControl(null, [...(validators['agencyAbbreviation'] ?? []), ...[Validators.required, Validators.maxLength(5), Validators.pattern("[A-Z]+")]]),
			'fein': new UntypedFormControl(null, [...(validators['fein'] ?? []), ...[Validators.minLength(10), Validators.maxLength(10), Validators.required]]),
			'mmcapAnticipatedDate': new UntypedFormControl(null, [...(validators['mmcapAnticipatedDate'] ?? []), ...[]]),
			'taxStatus': new UntypedFormControl('', [...(validators['taxStatus'] ?? []), ...[Validators.required]]),
			'strTaxStatus': new UntypedFormControl(null, [...(validators['strTaxStatus'] ?? []), ...[]]),
			'emsStateId': new UntypedFormControl(null, [...(validators['emsStateId'] ?? []), ...[]]),
			'mmcapAccountNumber': new UntypedFormControl(null, [...(validators['mmcapAccountNumber'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'otherTaxStatus': new UntypedFormControl(null, [...(validators['otherTaxStatus'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'county': new UntypedFormControl(null, [...(validators['county'] ?? []), ...[Validators.required]]),
			'countyName': new UntypedFormControl(null, [...(validators['countyName'] ?? []), ...[]]),
			'isMmcap': new UntypedFormControl(null, [...(validators['isMmcap'] ?? []), ...[Validators.required]]),
			'orgType': new UntypedFormControl('', [...(validators['orgType'] ?? []), ...[Validators.required]]),
			'strOrgType': new UntypedFormControl(null, [...(validators['strOrgType'] ?? []), ...[]]),
			'operationCounty': new UntypedFormControl(null, [...(validators['operationCounty'] ?? []), ...[Validators.required]]),
			'operationCountyName': new UntypedFormControl(null, [...(validators['operationCountyName'] ?? []), ...[]]),
			'otherOrgType': new UntypedFormControl(null, [...(validators['otherOrgType'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'useAgencyInfoForShipInfo': new UntypedFormControl(false, [...(validators['useAgencyInfoForShipInfo'] ?? []), ...[]]),
			'address': AddressInformationVm.Form,
			'agencySmartyAddress': new UntypedFormControl(null, [...(validators['agencySmartyAddress'] ?? []), ...[Validators.required]]),
			'agencySmartyAddressString': new UntypedFormControl(null, [...(validators['agencySmartyAddressString'] ?? []), ...[]]),
			'hasAgencySmarty': new UntypedFormControl(false, [...(validators['hasAgencySmarty'] ?? []), ...[]]),
			'shippingInformation': AddressInformationVm.Form,
			'shipSmartyAddress': new UntypedFormControl(null, [...(validators['shipSmartyAddress'] ?? []), ...[Validators.required]]),
			'shipSmartyAddressString': new UntypedFormControl(null, [...(validators['shipSmartyAddressString'] ?? []), ...[]]),
			'hasShipSmarty': new UntypedFormControl(false, [...(validators['hasShipSmarty'] ?? []), ...[]]),
			'agencyHeadName': new UntypedFormControl(null, [...(validators['agencyHeadName'] ?? []), ...[Validators.required, Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'agencyHeadTitle': new UntypedFormControl(null, [...(validators['agencyHeadTitle'] ?? []), ...[Validators.required, Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'agencyHeadEmail': new UntypedFormControl(null, [...(validators['agencyHeadEmail'] ?? []), ...[Validators.required, Validators.email, Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\-._%+'-)]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'agencyHeadPhoneNumber': new UntypedFormControl(null, [...(validators['agencyHeadPhoneNumber'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.minLength(0)]]),
			'agencyHasApplications': new UntypedFormControl(false, [...(validators['agencyHasApplications'] ?? []), ...[]]),
			'disableAgency': new UntypedFormControl(false, [...(validators['disableAgency'] ?? []), ...[]]),
			'hasInitialApproval': new UntypedFormControl(false, [...(validators['hasInitialApproval'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'primaryName': new UntypedFormControl(null, [...(validators['primaryName'] ?? []), ...[Validators.required, Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'primaryTitle': new UntypedFormControl(null, [...(validators['primaryTitle'] ?? []), ...[Validators.required, Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'primaryEmail': new UntypedFormControl(null, [...(validators['primaryEmail'] ?? []), ...[Validators.required, Validators.email, Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\-._%+'-)]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'primaryPhone': new UntypedFormControl(null, [...(validators['primaryPhone'] ?? []), ...[Validators.required]]),
			'primaryUserId': new UntypedFormControl(null, [...(validators['primaryUserId'] ?? []), ...[]]),
			'secondaryName': new UntypedFormControl(null, [...(validators['secondaryName'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'secondaryTitle': new UntypedFormControl(null, [...(validators['secondaryTitle'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'secondaryEmail': new UntypedFormControl(null, [...(validators['secondaryEmail'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\-._%+'-)]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'secondaryPhone': new UntypedFormControl(null, [...(validators['secondaryPhone'] ?? []), ...[]]),
			'secondaryUserId': new UntypedFormControl(null, [...(validators['secondaryUserId'] ?? []), ...[]]),
			'tertiaryName': new UntypedFormControl(null, [...(validators['tertiaryName'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'tertiaryTitle': new UntypedFormControl(null, [...(validators['tertiaryTitle'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\s\\.\\,\\-\\']*$")]]),
			'tertiaryEmail': new UntypedFormControl(null, [...(validators['tertiaryEmail'] ?? []), ...[Validators.email, Validators.maxLength(100), Validators.minLength(0), Validators.pattern("^[a-zA-Z0-9\\-._%+'-)]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'tertiaryPhone': new UntypedFormControl(null, [...(validators['tertiaryPhone'] ?? []), ...[]]),
			'tertiaryUserId': new UntypedFormControl(null, [...(validators['tertiaryUserId'] ?? []), ...[]]),
			'canEditHistoric': new UntypedFormControl(null, [...(validators['canEditHistoric'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'mmcapVerified': new UntypedFormControl(false, [...(validators['mmcapVerified'] ?? []), ...[]]),
			'isDeactivateConfirmed': new UntypedFormControl(null, [...(validators['isDeactivateConfirmed'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditAgencyVmFromFg {
		return new EditAgencyVmFromFg(fg);
	}
}

export class EditAgencyVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get agencyId(): number {
		return this.fg.get('agencyId').value;
	}
	set agencyId(val) {
		this.fg.get('agencyId').setValue(val);
	}

	get agencyName(): string {
		return this.fg.get('agencyName').value;
	}
	set agencyName(val) {
		this.fg.get('agencyName').setValue(val);
	}

	get agencyAbbreviation(): string {
		return this.fg.get('agencyAbbreviation').value;
	}
	set agencyAbbreviation(val) {
		this.fg.get('agencyAbbreviation').setValue(val);
	}

	get fein(): string {
		return this.fg.get('fein').value;
	}
	set fein(val) {
		this.fg.get('fein').setValue(val);
	}

	get mmcapAnticipatedDate(): Date {
		return this.fg.get('mmcapAnticipatedDate').value;
	}
	set mmcapAnticipatedDate(val) {
		this.fg.get('mmcapAnticipatedDate').setValue(val);
	}

	get taxStatus(): any {
		return this.fg.get('taxStatus').value;
	}
	set taxStatus(val) {
		this.fg.get('taxStatus').setValue(val);
	}

	get strTaxStatus(): string {
		return this.fg.get('strTaxStatus').value;
	}
	set strTaxStatus(val) {
		this.fg.get('strTaxStatus').setValue(val);
	}

	get emsStateId(): string {
		return this.fg.get('emsStateId').value;
	}
	set emsStateId(val) {
		this.fg.get('emsStateId').setValue(val);
	}

	get mmcapAccountNumber(): string {
		return this.fg.get('mmcapAccountNumber').value;
	}
	set mmcapAccountNumber(val) {
		this.fg.get('mmcapAccountNumber').setValue(val);
	}

	get otherTaxStatus(): string {
		return this.fg.get('otherTaxStatus').value;
	}
	set otherTaxStatus(val) {
		this.fg.get('otherTaxStatus').setValue(val);
	}

	get county(): number {
		return this.fg.get('county').value;
	}
	set county(val) {
		this.fg.get('county').setValue(val);
	}

	get countyName(): string {
		return this.fg.get('countyName').value;
	}
	set countyName(val) {
		this.fg.get('countyName').setValue(val);
	}

	get isMmcap(): boolean {
		return this.fg.get('isMmcap').value;
	}
	set isMmcap(val) {
		this.fg.get('isMmcap').setValue(val);
	}

	get orgType(): any {
		return this.fg.get('orgType').value;
	}
	set orgType(val) {
		this.fg.get('orgType').setValue(val);
	}

	get strOrgType(): string {
		return this.fg.get('strOrgType').value;
	}
	set strOrgType(val) {
		this.fg.get('strOrgType').setValue(val);
	}

	get operationCounty(): number {
		return this.fg.get('operationCounty').value;
	}
	set operationCounty(val) {
		this.fg.get('operationCounty').setValue(val);
	}

	get operationCountyName(): string {
		return this.fg.get('operationCountyName').value;
	}
	set operationCountyName(val) {
		this.fg.get('operationCountyName').setValue(val);
	}

	get otherOrgType(): string {
		return this.fg.get('otherOrgType').value;
	}
	set otherOrgType(val) {
		this.fg.get('otherOrgType').setValue(val);
	}

	get useAgencyInfoForShipInfo(): boolean {
		return this.fg.get('useAgencyInfoForShipInfo').value;
	}
	set useAgencyInfoForShipInfo(val) {
		this.fg.get('useAgencyInfoForShipInfo').setValue(val);
	}

	get address(): AddressInformationVmFromFg {
		return new AddressInformationVmFromFg(this.fg.get('address') as UntypedFormGroup);
	}
	set address(val) {
		(this.fg.get('address') as UntypedFormGroup).setValue(val);
	}

	get agencySmartyAddress(): any {
		return this.fg.get('agencySmartyAddress').value;
	}
	set agencySmartyAddress(val) {
		this.fg.get('agencySmartyAddress').setValue(val);
	}

	get agencySmartyAddressString(): string {
		return this.fg.get('agencySmartyAddressString').value;
	}
	set agencySmartyAddressString(val) {
		this.fg.get('agencySmartyAddressString').setValue(val);
	}

	get hasAgencySmarty(): boolean {
		return this.fg.get('hasAgencySmarty').value;
	}
	set hasAgencySmarty(val) {
		this.fg.get('hasAgencySmarty').setValue(val);
	}

	get shippingInformation(): AddressInformationVmFromFg {
		return new AddressInformationVmFromFg(this.fg.get('shippingInformation') as UntypedFormGroup);
	}
	set shippingInformation(val) {
		(this.fg.get('shippingInformation') as UntypedFormGroup).setValue(val);
	}

	get shipSmartyAddress(): any {
		return this.fg.get('shipSmartyAddress').value;
	}
	set shipSmartyAddress(val) {
		this.fg.get('shipSmartyAddress').setValue(val);
	}

	get shipSmartyAddressString(): string {
		return this.fg.get('shipSmartyAddressString').value;
	}
	set shipSmartyAddressString(val) {
		this.fg.get('shipSmartyAddressString').setValue(val);
	}

	get hasShipSmarty(): boolean {
		return this.fg.get('hasShipSmarty').value;
	}
	set hasShipSmarty(val) {
		this.fg.get('hasShipSmarty').setValue(val);
	}

	get agencyHeadName(): string {
		return this.fg.get('agencyHeadName').value;
	}
	set agencyHeadName(val) {
		this.fg.get('agencyHeadName').setValue(val);
	}

	get agencyHeadTitle(): string {
		return this.fg.get('agencyHeadTitle').value;
	}
	set agencyHeadTitle(val) {
		this.fg.get('agencyHeadTitle').setValue(val);
	}

	get agencyHeadEmail(): string {
		return this.fg.get('agencyHeadEmail').value;
	}
	set agencyHeadEmail(val) {
		this.fg.get('agencyHeadEmail').setValue(val);
	}

	get agencyHeadPhoneNumber(): string {
		return this.fg.get('agencyHeadPhoneNumber').value;
	}
	set agencyHeadPhoneNumber(val) {
		this.fg.get('agencyHeadPhoneNumber').setValue(val);
	}

	get agencyHasApplications(): boolean {
		return this.fg.get('agencyHasApplications').value;
	}
	set agencyHasApplications(val) {
		this.fg.get('agencyHasApplications').setValue(val);
	}

	get disableAgency(): boolean {
		return this.fg.get('disableAgency').value;
	}
	set disableAgency(val) {
		this.fg.get('disableAgency').setValue(val);
	}

	get hasInitialApproval(): boolean {
		return this.fg.get('hasInitialApproval').value;
	}
	set hasInitialApproval(val) {
		this.fg.get('hasInitialApproval').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get primaryName(): string {
		return this.fg.get('primaryName').value;
	}
	set primaryName(val) {
		this.fg.get('primaryName').setValue(val);
	}

	get primaryTitle(): string {
		return this.fg.get('primaryTitle').value;
	}
	set primaryTitle(val) {
		this.fg.get('primaryTitle').setValue(val);
	}

	get primaryEmail(): string {
		return this.fg.get('primaryEmail').value;
	}
	set primaryEmail(val) {
		this.fg.get('primaryEmail').setValue(val);
	}

	get primaryPhone(): string {
		return this.fg.get('primaryPhone').value;
	}
	set primaryPhone(val) {
		this.fg.get('primaryPhone').setValue(val);
	}

	get primaryUserId(): number {
		return this.fg.get('primaryUserId').value;
	}
	set primaryUserId(val) {
		this.fg.get('primaryUserId').setValue(val);
	}

	get secondaryName(): string {
		return this.fg.get('secondaryName').value;
	}
	set secondaryName(val) {
		this.fg.get('secondaryName').setValue(val);
	}

	get secondaryTitle(): string {
		return this.fg.get('secondaryTitle').value;
	}
	set secondaryTitle(val) {
		this.fg.get('secondaryTitle').setValue(val);
	}

	get secondaryEmail(): string {
		return this.fg.get('secondaryEmail').value;
	}
	set secondaryEmail(val) {
		this.fg.get('secondaryEmail').setValue(val);
	}

	get secondaryPhone(): string {
		return this.fg.get('secondaryPhone').value;
	}
	set secondaryPhone(val) {
		this.fg.get('secondaryPhone').setValue(val);
	}

	get secondaryUserId(): number {
		return this.fg.get('secondaryUserId').value;
	}
	set secondaryUserId(val) {
		this.fg.get('secondaryUserId').setValue(val);
	}

	get tertiaryName(): string {
		return this.fg.get('tertiaryName').value;
	}
	set tertiaryName(val) {
		this.fg.get('tertiaryName').setValue(val);
	}

	get tertiaryTitle(): string {
		return this.fg.get('tertiaryTitle').value;
	}
	set tertiaryTitle(val) {
		this.fg.get('tertiaryTitle').setValue(val);
	}

	get tertiaryEmail(): string {
		return this.fg.get('tertiaryEmail').value;
	}
	set tertiaryEmail(val) {
		this.fg.get('tertiaryEmail').setValue(val);
	}

	get tertiaryPhone(): string {
		return this.fg.get('tertiaryPhone').value;
	}
	set tertiaryPhone(val) {
		this.fg.get('tertiaryPhone').setValue(val);
	}

	get tertiaryUserId(): number {
		return this.fg.get('tertiaryUserId').value;
	}
	set tertiaryUserId(val) {
		this.fg.get('tertiaryUserId').setValue(val);
	}

	get canEditHistoric(): boolean {
		return this.fg.get('canEditHistoric').value;
	}
	set canEditHistoric(val) {
		this.fg.get('canEditHistoric').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get mmcapVerified(): boolean {
		return this.fg.get('mmcapVerified').value;
	}
	set mmcapVerified(val) {
		this.fg.get('mmcapVerified').setValue(val);
	}

	get isDeactivateConfirmed(): boolean {
		return this.fg.get('isDeactivateConfirmed').value;
	}
	set isDeactivateConfirmed(val) {
		this.fg.get('isDeactivateConfirmed').setValue(val);
	}
}
