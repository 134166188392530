import { NavInventorySiteVm } from 'app/shared/generated/Administration/Models/Users/NavInventorySiteVm';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getPharmacyNav: () => NavRoute = () => {

	const user = getUser();

	if ((user?.inventorySites?.length ?? 0) !== 0) {
		const navItems: NavRoute[] = user.inventorySites.map((o, i) => (
			{
				name: o.name
				, children: getInventoryAreas(o)
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Inventory ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
					}
				]
				, shouldDisplay: () => isVisibleLink(o.name)
				, ellipseAfter: i === 0
				, ellipseBefore: i === user.inventorySites.length - 1
				, href: `inventory/products/${o.id}`
			})
		);

		// Always show the View All Sites link, regardless of site paging
		navItems.push({
			name: 'View All Inventories'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
			, path: 'inventory/inventory-list'
		});

		var adminSettingsNavItems: NavRoute[]  = [
			{
				name: 'Inventory'
				, href: 'administration/inventory/inventory-actions'
				, children: [
					{
						name: 'Inventory Actions'
						, exact: true
						, path: 'administration/inventory/inventory-actions'
					}
					, { 
						name: 'Programs'
						, exact: true
						, path: 'inventory/programs'
					}
					, {
						name: 'Departments'
						, exact: true
						, path: 'administration/departments'
					}
				]
			}
			, {
				name: 'Pharm Log'
				, href: 'administration/pharm-log/script-status'
				, children: [
					{
						name: 'Script Status'
						, exact: true
						, path: 'administration/pharm-log/script-status'
					}
					, {
						name: 'Non Rx Types'
						, exact: true
						, path: 'administration/pharm-log/non-rx-types'
					}
				]
			}
			, {
				name: 'Verification Settings'
				, exact: true
				, path: 'inventory/verification-settings'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Inventory ]
						, permissionRoles: [ PermissionRole.Technician ]
					}
				]
			}
		];

		if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.Inventory)) {
			adminSettingsNavItems.push({
				name: 'Error Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Inventory ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
				, exact: true
				, path: `error-report/error-report-list/${BusinessAreaEnum.Inventory}`
			});
		}

		return {
			name: 'Pharmacy'
			, href: 'fulfillment/fulfillment'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, children: [
				{
					name: 'Rx Fulfillment'
					, exact: true
					, path: 'fulfillment/fulfillment'
				}
				, {
					name: 'Inventories'
					, children: navItems
					, href: 'inventory/inventory-list'
				}
				, {
					name: 'Pharm Log'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.Inventory ]
							, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
						}
					]
					, exact: true
					, path: 'pharm-log/pharm-log-list'
				}
				, {
					name: 'Inventory Changes'
					, href: 'inventory/inventory-changes'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.Inventory ]
							, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
						}
					]
					, exact: true
					, path: 'inventory/inventory-changes'
				}
				, {
					name: 'Reports'
					, href: 'reports/fulfillment-turn-around-report'
					, children: [
						{
							name: 'Fulfillment Turnaround'
							, exact: true
							, path: 'reports/fulfillment-turn-around-report'
						}
						, {
							name: 'Overage Shortage'
							, exact: true
							, path: 'reports/overage-shortage-report'
						}
						, {
							name: 'QC Check'
							, exact: true
							, path: 'reports/qccheck-report'
							, moduleAccess: [
								{
									modules: [ BusinessAreaEnum.Inventory ]
									, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
								}
								, {
									modules: [ BusinessAreaEnum.Admin ]
									, permissionRoles: [ PermissionRole.SystemAdmin ]
								}
							]
							, requireAll: true
						}
						, {
							name: 'Reorder'
							, exact: true
							, path: 'reports/reorder-report'
						}
						, {
							name: 'Pharmacy Inventory Change'
							, exact: true
							, path: 'reports/pharmacy-inventory-change'
						}
						, {
							name: 'Rx Tracking Number'
							, exact: true
							, path: 'reports/rx-tracking-number-report'
							, moduleAccess: [
								{
									modules: [ BusinessAreaEnum.Inventory ]
									, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
								}
							]
						}
						, {
							name: 'Fulfillment Deletion'
							, exact: true
							, path: 'reports/fulfillment-deletion-report'
							, moduleAccess: [
								{
									modules: [ BusinessAreaEnum.Inventory ]
									, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
								}
							]
						}
					]
				}
				, {
					name: 'Admin Settings'
					, moduleAccess: [
						{ 
							modules: [ 
								BusinessAreaEnum.Admin
								, BusinessAreaEnum.Inventory
							]
							, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist) 
						}
					]
					, href: 'administration/inventory/inventory-actions'
					, children: adminSettingsNavItems
				}
			]
		};
	}

	return {
		name: 'Pharmacy'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Inventory ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
			}
		]
		,
		children: [
			{
				name: 'Rx Fulfillment'
				, exact: true
				, path: 'fulfillment/fulfillment'
			}
			, {
				name: 'Inventories'
				, exact: true
				, path: 'inventory/inventory-list'
			}
			, {
				name: 'Pharm Log'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Inventory ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
				, exact: true
				, path: 'pharm-log/pharm-log-list'
			}
			, {
				name: 'Inventory Changes'
				, href: 'inventory/inventory-changes'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Inventory ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
				, exact: true
				, path: 'inventory/inventory-changes'
			}
			, {
				name: 'Reports'
				, href: 'reports/fulfillment-turn-around-report'
				, children: [
					{
						name: 'Fulfillment Turnaround Report'
						, exact: true
						, path: 'reports/fulfillment-turn-around-report'
					}
					, {
						name: 'Overage Shortage Report'
						, exact: true
						, path: 'reports/overage-shortage-report'
					}
					, {
						name: 'QC Check Report'
						, exact: true
						, path: 'reports/qccheck-report'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.Inventory ]
								, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
							}
							, {
								modules: [ BusinessAreaEnum.Admin ]
								, permissionRoles: [ PermissionRole.SystemAdmin ]
							}
						]
						, requireAll: true
					}
					, {
						name: 'Reorder Report'
						, exact: true
						, path: 'reports/reorder-report'
					}
					, {
						name: 'Pharmacy Inventory Change Report'
						, exact: true
						, path: 'reports/pharmacy-inventory-change'
					}
					, {
						name: 'Rx Tracking Number Report'
						, exact: true
						, path: 'reports/rx-tracking-number-report'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.Inventory ]
								, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
							}
						]
					}
					, {
						name: 'Fulfillment Deletion Report'
						, exact: true
						, path: 'reports/fulfillment-deletion-report'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.Inventory ]
								, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
							}
						]
					}
				]
			}
		]
	};
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function getInventoryAreas(inventorySite: NavInventorySiteVm): NavRoute[] {
	return [
		{
			name: 'Products'
			, path: `inventory/products/${inventorySite.id}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Transaction History'
			, path: `inventory/transaction-history/${inventorySite.id}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Reconciliation'
			, path: `inventory/reconciliation/${inventorySite.id}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Replenishment'
			, path: `inventory/replenishment/${inventorySite.id}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Rx Fulfillment'
			, path: `inventory/fulfillment/${inventorySite.id}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Transfers'
			, path: `inventory/transfers/${inventorySite.id}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Invoices'
			, path: `inventory/invoices/${inventorySite.id}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
	];
}

/**
 * Get the currently logged in user.
 *
 * Should remove after refactoring to generate
 * nav routes in an Angular service and use
 * security service to get it.
 */
function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}

/**
 * Determine whether an inventory site subnav item should be displayed.
 * @param currSelectedNum The index of the currently selected subnav item. Null if none.
 * @param myNum The index of the subnav item we are checking.
 * @param linkCount The total number of subnav items.
 */
function isVisibleLink(name: string) {
	if (name.includes("Current")) { return true; }
	else if (name.includes("Quarantine")) { return false; }
	// Otherwise, do not show the current nav item
	return false;
}
