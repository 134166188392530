import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';

export const getHelpDeskNav: () => NavRoute = () => {
	const user = getUser();
	const sec = SecurityService;
	const p = PermissionRole;

	if (user !== null) {
		var adminSettingsNavItems: NavRoute[] = [
			{
				name: 'Categories'
				, exact: true
				, path: 'support/admin/categories'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: sec.setMinRole(p.SystemAdmin)
					}
				]
			}
			, {
				name: 'Priorities'
				, exact: true
				, path: 'support/admin/priorities'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: sec.setMinRole(p.SystemAdmin)
					}
				]
			}
			, {
				name: 'Statuses'
				, exact: true
				, path: 'support/admin/statuses'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: sec.setMinRole(p.SystemAdmin)
					}
				]
			}
			, {
				name: 'Template Responses'
				, exact: true
				, path: 'support/admin/template-responses'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: [ p.SystemAdmin ]
					}
				]
			}
			, {
				name: 'Dashboard Settings'
				, href: 'support/admin/support-settings'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: sec.setMinRole(p.SystemAdmin)
					}
				] 
				, children: [
					{
						name: 'Support Settings'
						, exact: true
						, path: 'support/admin/support-settings'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.HelpDesk ]
								, permissionRoles: [ p.SystemAdmin ]
							}
						]
					}
					, {
						name: 'Upcoming Features'
						, exact: true
						, path: 'support/admin/upcoming-features'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.HelpDesk ]
								, permissionRoles: [ p.SystemAdmin ]
							}
						]
					}
				]
			}
		];

		if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.HelpDesk)) {
			adminSettingsNavItems.push({
				name: 'Error Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, exact: true
				, path: `error-report/error-report-list/${BusinessAreaEnum.HelpDesk}`
			});
		}

		return {
			name: 'Support'
			, href: 'support/dashboard'
			, canActivate: [ AuthGuard ]
			, moduleAccess: [
				{
					modules: sec.anyArea()
					, permissionRoles: sec.setMinRole(p.User)
				}
			]
			, children: [
				{
					name: 'Dashboard'
					, exact: true
					, path: 'support/dashboard'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
				}				
				, {
					name: 'Support Desk'
					, exact: true
					, path: 'support/tickets'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
				}
				, {
					name: 'Knowledge Base'
					, exact: true
					, path: 'knowledge-base'
					, moduleAccess: [
						{
							modules: sec.anyArea()
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
				}				
				, {
					name: 'Release Notes'
					, exact: true
					, path: '/web-release-notes'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
				}
				, {
					name: 'Admin Settings'
					, href: 'support/admin/categories'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
					, children: adminSettingsNavItems
				}
			]
		};
	}

	return { name: "" };
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}