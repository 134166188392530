<pcg-table-top 
	*ngIf="showTableTop" 
	#tableTop
	[canGlobalSearch]="canGlobalSearch" 
	[canShowHideColumns]="canShowHideColumns" 
	[pageSizeSource]='pageSizeSource' 
	[filterSource]='filterSource'
	[pageLengths]='pageLengths' 
	[exactMatchSource]='exactMatchSource' 
	[columnDefs]='columnDefs' 
	[filterId]="filterId" 
	[filters]="(filterId ? ajaxData : null)" 
	[emptyFilters]="emptyFilters" 
	[customResetFiltersMap]="customResetFiltersMap"
	[ajaxData]="ajaxData"
	[canResetFilters]="canResetFilters"
	[filterMap]="filterMap"
	[canExportTableToExcel]="canExportTableToExcel" 
	[canPrintTable]="canPrintTable"
	[hasTooltips]="hasTooltips"
	[showTooltipsBtn]="showTooltipsBtn"
	[tooltipContent]="tooltipContent"
	[table]="this" 	
	[showTilesBtn]="showTilesBtn"
	(isUsingTiles)="tableViewClick($event)"
	[tableViewStorage]="tableViewStorage"

	[canExactMatchSearch]="canExactMatchSearch"	
	[canExportTableToPdf]="canExportTableToPdf" 
	[excelUrl]="excelUrl" 
	[printUrl]="printUrl" 
	[pdfUrl]="pdfUrl"
	[excelTooltip]="excelTooltip" 
	[printTooltip]="printTooltip" 
	[pdfTooltip]="pdfTooltip"
	[customExcelFunc]='customExcelFunc' 
	ignoreUniqueColumnName="true"
></pcg-table-top>

<tr 
	class='search-row no-print' 
	*matHeaderRowDef='getColHeaderDefs()' 
	mat-header-row
></tr>
<tr 
	class='footer-row' 
	*matFooterRowDef='getVisibleColDefs()' 
	mat-footer-row
></tr>

<!-- Mobile cards -->
<div 
	*ngIf="
		isMobile 
		&& isUsingMobileCard 
		&& !isNestedTable
	"
>
	<div 
		*ngFor="let data of tableData?.data" 
		class="list-card"
		(click)="redirectFunctionEmit ? clickRow.emit(data) : redirectFunction(data)"
	>
		<div 
			class="unique-field {{mobileClasses}}" 
			[ngClass]="{'discrepancy-color' : data?.isDanger}"
		>
			<div class="alert-field">
				<h8 
					*ngIf="data?.isDanger" 
					class="alert-bubble"
				>DANGER</h8>
			</div>

			@if (showMobileEdit) {
				<pcg-edit-link [url]="editLink + data[identificationColumn]">
					<fa-icon 
						[icon]="faPenToSquare" 
						class="pen-to-square"
					></fa-icon>
				</pcg-edit-link>
			}

			<div class="unique-item">
				<p 
					*ngIf="!ignoreUniqueColumnName" 
					class="unique-item-text"
				>{{ columnDefs.get(uniqueColumn).displayName }}</p>
				<h6 
					*ngIf="!mobileMap?.has(uniqueColumn)" 
					class="unique-item-text"
				>{{ data[uniqueColumn] }}</h6>
				<p 
					*ngIf="hasSecondUniqueColName" 
					class="unique-item-text"
				>{{ data[secondUniqueColumn] }}</p>
				<div 
					*ngIf="mobileMap?.has(uniqueColumn)" 
					class="unique-item-text" 
					[innerHTML]="mobileMap?.get(uniqueColumn)(data)"
				></div>
			</div>

			<div class="delete-field">
				<pcg-delete-button 
					*ngIf="getVisibleColDefs().includes('canDelete')" 
					class="delete-btn" 
					[table]="this"
					[confirmMessage]="confirmMessage" 
					[url]="deleteUrl" 
					[id]="data[identificationColumn]"
					useTrashIcon="true"
				></pcg-delete-button>
			</div>
		</div>

		<div 
			*ngIf="getMobileColDefs().length > 0" 
			class="list-field"
		>
			<div 
				*ngFor="let row of getMobileColDefs()" 
				class="card-list-item"
			>
				<p>{{ columnDefs.get(row).displayName }}</p>
				<h6 *ngIf="!mobileMap?.has(row)">{{ data[row] }}</h6>
				<div 
					*ngIf="mobileMap?.has(row)" 
					[innerHTML]="mobileMap?.get(row)(data)"
				></div>
			</div>
		</div>
	</div>

	<div 
		*ngIf="tableData?.data?.length === 0" 
		class="no-records"
	>No records to display</div>
</div>

<!-- Desktop Table -->
<div 
	[class.d-none]="
		isMobile 
		&& isUsingMobileCard
	" 
	role="region" 
	aria-label="Table" 
	#tableContainer
	[class.pcg-table-responsive]="isResponsive" [class.pcg-table-fixed-footer]='fixedFooter'
	[class.pcg-table-fixed-header]="fixedHeader" class='pcg-table-container mb-1'
>
	<!-- Table Tiles -->
	<div 
		*ngIf="showTiles" 
		class="row mt-2"
	>
		<div class="tile-card-container">
			<div 
				*ngFor="let data of tableData?.data" 
				style="flex: .25; min-width: 20%;"
			>
				<div class="card mb-2 tile-card">
					<div class="tile-card-header">
						<div class="row">
							<div class="col-md-9">
								<h6>{{ data[tileHeaderColumn] }}</h6>
							</div>
							<div class="col-md-3 text-right">
								<pcg-edit-link [url]="editLink + data[identificationColumn]">
									<fa-icon 
										[icon]="faPenToSquare" 
										class="pen-to-square"
									></fa-icon>
								</pcg-edit-link>
							</div>
						</div>
						<div 
							*ngIf="data[tileSubHeaderColumn] !== ''" 
							class="row"
						>
							<div class="col-md-12">
								<h6 class="card-subtitle mt-2 text-muted">
									{{ data[tileSubHeaderColumn] }}
								</h6>
							</div>
						</div>
					</div>

					<div 
						class="text-nowrap text-left"
						(click)="redirectFunction(data)"
					>
						<ul class="list-group list-group-flush">
							<li class="list-group-item">
								<div 
									*ngFor="let row of tilesMap" 
									class="tile-list-item"
								>
									<h6 
										class="left" 
										[innerHtml]="columnDefs.get(row[0]).displayName"
									></h6>
									<span 
										class="right" 
										[innerHTML]="row[1](data)"
									></span>
								</div>
							</li>
							<li 
								*ngIf="
									getVisibleColDefs().includes('canDelete') 
									&& (
										data['canDelete'] 
										|| isAdmin
									)
								" 
								class="list-group-item"
							>
								<pcg-delete-button
									class="delete-btn-table"
									[table]="this"
									[confirmMessage]="confirmMessage"
									[url]="deleteUrl"
									[id]="data[identificationColumn]"
									[isUsingComment]="isUsingDeleteComment"
								></pcg-delete-button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div 
			*ngIf="tableData?.data?.length === 0" 
			class="no-records-table"
		>No records to display</div>
	</div>

	<!-- Table list -->
	<table 
		[dataSource]='dataOnPageSource' 
		mat-table 
		class='data-grid' 
		[class]='"pcg-table-" + tableNum'
		id="pcgDataTable" 
		[class.d-none]="isNestedTable || showTiles"
	>
		<!-- Displayed if no records are in the table -->
		<ng-container matColumnDef="noRecords">
			<td 
				colspan='999' 
				mat-cell 
				*matCellDef='let row'
			>
				<div *ngIf='hasCompletedServerRequest || !serverSide'>No records to display</div>
				<div *ngIf='!hasCompletedServerRequest && serverSide'>Loading...</div>
			</td>
		</ng-container>

		<!-- Optional per column search -->
		<ng-container 
			*ngFor='let col of getColDefs(); let i = index' 
			[matColumnDef]="col + '_search'"
		>
			<th 
				[class]='getColSearchClasses(col)' 
				mat-header-cell 
				*matHeaderCellDef='let row'
			>
				<input 
					*ngIf="
						canSearch(col) 
						&& isNdc(col)
					" 
					[value]="perColumnSearchSource.value[i]" 
					(keyup)="updatePerColumnSearch(i, $event.target.value)"
					(search)="updatePerColumnSearch(i, $event.target.value)"
					[attr.aria-label]="'Search ' + columnDefs.get(col)?.displayName" 
					placeholder="Search..."
					class="form-control form-control-sm hide-on-print" 
					mask="00000-0000-00" 
					type="search" 
				/>
				<input 
					*ngIf="
						canSearch(col) 
						&& isNdc10(col)
					" 
					[value]="perColumnSearchSource.value[i]" 
					(keyup)="updatePerColumnSearch(i, $event.target.value)"
					(search)="updatePerColumnSearch(i, $event.target.value)"
					[attr.aria-label]="'Search ' + columnDefs.get(col)?.displayName" 
					placeholder="Search..."
					class="form-control form-control-sm hide-on-print" 
					mask="0000000000" 
					type="search" 
				/>
				<input 
					*ngIf="
						canSearch(col) 
						&& (
							!isNdc(col) 
							&& !isNdc10(col)
						)
					" 
					[value]="perColumnSearchSource.value[i]" 
					(keyup)="updatePerColumnSearch(i, $event.target.value)"
					(search)="updatePerColumnSearch(i, $event.target.value)"
					[attr.aria-label]="'Search ' + columnDefs.get(col)?.displayName" 
					placeholder="Search..."
					class="form-control form-control-sm hide-on-print" 
					type="search" 
				/>
			</th>
		</ng-container>

		<!-- Header and Row Declarations -->
		<tr 
			mat-header-row 
			*matHeaderRowDef='getVisibleColDefs()' 
			class="reduce-height"
		></tr>
		<tr 
			mat-row 
			*matRowDef='let row; when: hasData; columns: getVisibleColDefs()' 
			(click)="emitClick(row)"
			[class]="isRowClickable === true ? 'clickable-row' : ''"
		></tr>
		<tr 
			mat-row 
			*matRowDef='let row; when: hasNoData; columns: ["noRecords"]'
		></tr>
	</table>

	<p-treeTable 
		*ngIf='
			isNestedTable 
			&& !showTiles
		'
		#treeTable 
		[value]='nestedData' 
		[columns]='getVisibleColDefs()'
		[paginator]='showNumRows' 
		[(rows)]='pageSize' 
		[totalRecords]='filteredDataCount' 
		[loading]='loading'
		columnResizeMode='expand' 
		[responsive]='true' 
		[class]='"data-grid pcg-table-" + tableNum' 
		[resizableColumns]='true'
		[sortField]='defaultSort' 
		[rowsPerPageOptions]='pageLengths' 
		[scrollable]='true' 
		paginatorStyleClass='d-none' 
		[scrollHeight]="scrollHeight" 
		(onNodeExpand)='setScroll()' 
		(onNodeCollapse)='setScroll()' 
		[globalFilterFields]='getSearchColDefs()'
	>

		<ng-template 
			pTemplate="header" 
			let-columns
		>
			<tr>
				<th 
					*ngFor="let col of columns; let i = index" 
					[ttSortableColumn]="col"
					[class]='columnDefs.get(col)?.cellClass'
				>
					<span class='d-flex flex-row align-items-center'>
						<span 
							*ngIf="
								i === 0 
								&& nestedData?.length
							" 
							class='mx-3 pr-3'
						>
							<span 
								*ngIf="!isExpanded" 
								(click)='toggleNodes()' 
								class='expand-button'
							>+</span>
							<span 
								*ngIf="isExpanded"
								(click)='toggleNodes()' 
								class='expand-button'
							>-</span>
						</span>
						<span class='d-flex align-items-center'>
							{{ columnDefs.get(col)?.displayName }}
							<p-treeTableSortIcon [field]="col" />
						</span>
					</span>
				</th>
			</tr>
		</ng-template>

		<ng-template 
			pTemplate="body" 
			let-rowNode 
			let-rowData="rowData" 
			let-columns="columns"
		>
			<tr [ttRow]="rowNode">
				<td 
					*ngFor="let col of columns; let i = index" 
					[class]='columnDefs.get(col)?.cellClass + " py-0"'
				>
					<span class='d-flex text-nowrap'>
						<p-treeTableToggler 
							*ngIf="i === 0" 
							[rowNode]="rowNode" 
							class='p-2 mx-2 pr-3 tree-toggler' 
						/>
						<span 
							class='p-2 w-100 pointer d-flex align-items-center' 
							[routerLink]='rowData[columnDefs.get(col).cellNavigationColumn]'					
							[class.disabled]='!rowData[columnDefs.get(col).cellNavigationColumn] ? "true" : null'
						>
							<ng-container [ngSwitch]='columnDefs.get(col)?.cellTransform'>
								<!-- HTML Cell for custom Transformation -->
								<ng-container *ngSwitchCase='0'>
									<span 
										[innerHTML]="rowData[col]" 
										class="d-flex"
									></span>
								</ng-container>
								<!-- Short Date Transform -->
								<ng-container *ngSwitchCase='1'>{{ rowData[col] | date: 'short' }}</ng-container>
								<!-- Number Transform -->
								<ng-container *ngSwitchCase='2'>{{ rowData[col] | number }}</ng-container>
								<!-- Percent Transform -->
								<ng-container *ngSwitchCase='3'>{{ rowData[col] | percent: '.2' }}</ng-container>
								<!-- Currency Transform -->
								<ng-container *ngSwitchCase='4'>{{ rowData[col] | currency }}</ng-container>
								<!-- Standard Cell -->
								<ng-container *ngSwitchDefault>{{ rowData[col] }}</ng-container>
							</ng-container>
						</span>
					</span>
				</td>
			</tr>
		</ng-template>

		<!-- Row to indicate table records or records loading -->
		<ng-template pTemplate="summary">
			<div
				*ngIf='
					(treeTable?.totalRecords ?? 0) == 0 
					|| !nestedData 
					|| !nestedData?.length 
					|| loading
				' 
				class='border d-flex align-items-center justify-content-center p-2'
			>
				<span *ngIf='!loading'>No records to display</span>
				<span *ngIf='loading'>Loading...</span>
			</div>
		</ng-template>

		<!-- Overriding the default collapse toggle icon -->
		<ng-template 
			pTemplate="togglericon" 
			let-open
		>
			<span 
				*ngIf="open" 
				(click)='isExpanded = false'
			>-</span>
			<span *ngIf="!open">+</span>
		</ng-template>

	</p-treeTable>
</div>	

<!-- Pagination and page count -->
<div 
	*ngIf="showNumRows" 
	class="d-flex align-items-start"
>
	<div class="bottom-left-container filter-count-container form-inline hide-on-print">
		<div class="d-flex align-items-start">
			<span class="sm">Show</span>
			<select 
				(change)="pageSizeSource.next($event.target.value)" 
				[class]="pageSizeSource.value < 1000 
					? 'form-control form-control-sm pageSize mx-sm-1 ml-1' 
					: 'form-control form-control-sm mx-sm-1 ml-1'
				"
			>
				<option 
					*ngFor="let length of pageLengths" 
					aria-label="Table page length"
					[selected]="pageSizeSource.value === length"
				>{{ length }}</option>
			</select>
		</div>
	</div>

	<div 
		*ngIf="showPagination" 
		class='w-100 mb-2 clearfix'
	>
		<div class='filter-count-container'>
			Showing
			{{ filteredDataCount === 0 ? 0 : ((currentPageSource.value - 1) * pageSizeSource.value + 1) | number }}
			to
			{{
				(filteredDataCount < currentPageSource.value * pageSizeSource.value 
					? filteredDataCount 
					: currentPageSource.value * pageSizeSource.value
				) | number 
			}} of {{ filteredDataCount | number }} 
			entries
			<span *ngIf="filteredDataCount !== totalDataCount">
				(filtered from {{ totalDataCount | number }} total entries)
			</span>
		</div>

		<!-- Page switcher -->
		<div class='pagination-container no-print'>
			<ngb-pagination 
				[collectionSize]="filteredDataCount" 
				[pageSize]="pageSizeSource.value"
				[page]="currentPageSource.value" 
				(pageChange)="currentPageSource.next($event)" 
				[maxSize]='5'
				[rotate]='true'
			></ngb-pagination>
		</div>
	</div>
</div>