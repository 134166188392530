import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getNdcDirectoryNav: () => NavRoute = () => {

	const user = getUser();
	var adminSettingsNavItems: NavRoute[] = [];

	if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.NDC)) {
		adminSettingsNavItems.push({
			name: 'Error Reporting'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.NDC ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, exact: true
			, path: `error-report/error-report-list/${BusinessAreaEnum.NDC}`
		});
	}

	return {
		name: 'NDC\'s'
		, href: 'redbook/redbook-list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.NDC ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, children: [
			{
				name: 'NDC Directory'
				, exact: true
				, path: 'ndc-directory/ndc-directory-list/'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.NDC ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
			}
			, {
				name: 'Red Book'
				, exact: true
				, path: 'redbook/redbook-list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.NDC ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
			}
			, {
				name: 'Admin Settings'
				, href: 'ndc-directory/admin/error-report-list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.NDC ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
				, children: adminSettingsNavItems
			}
		]
	};
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}