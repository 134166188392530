import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faCircleCheck, faFilePlus, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { PcgFile } from 'app/shared/form-elements/file-upload/file-upload.component';
import { GenericFilesVm } from 'app/shared/generated/Models/GenericFilesVm';
import { GlobalService } from 'app/shared/services/global.service';
import { FileHandle } from '../directives/drag-drop.directive';

@Component({
    selector: 'pcg-drag-drop-file-upload',
    templateUrl: './drag-drop-file-upload.component.html',
    styleUrls: ['./drag-drop-file-upload.component.scss']
})
export class DragDropFileUploadComponent implements OnInit, AfterViewInit {

    @Input() parentId: number;
    @Input() fileList: GenericFilesVm[];
    @Input() fileList$ : BehaviorSubject<GenericFilesVm[]>;
    @Input() fileListControl : string;
    @Input() formGroup: UntypedFormGroup;
    @Input() formGroupControlName: string;
    @Input() disabled = false;
    @Input() maxFiles: number = 0;
    @Input() minHeightPixels: number = 400;
    @Input() minWidthPixels: number = 400;
    @Input() deleteFileUrl: string;
    @Input() deleteEndpoint: string;
    @Input() allowedFilesMessage: string = "(Optional: up to 3, image, pdf, xlsx, xls)";
    @Input() allowedFiles: string = "image, pdf, xlsx, xls";
    @Input() navRoute = "";
    @Input() useFullNavRoute = false;
    @Input() canEdit = true;
    @Input() labelStr = "Upload Files";

    @Output() fileUploadEvents = new EventEmitter<UntypedFormGroup>();
    @Output() filesSaved = new EventEmitter();
    @Output() newFileStaged = new EventEmitter();

    subscriptions = new Subscription();
    uploadedFiles: FileHandle[] = [];
    pcgFiles: PcgFile[] = [];
    uploadFiles$: Observable<FileHandle[]>;

    showButtons = true;
    showDragDrop = false;
    isMobile: boolean;
    isError = false;

    fileCount = 0;

    errorString = "";
    imgFileTypes = " bmp, gif, png, jpg, jpeg, and tiff."

    faIconName = {
        faTrashCan
        , faCircleCheck
        , faFilePlus
    };

    constructor(
        private cdref: ChangeDetectorRef
        , private http: HttpClient
        , public sm: SystemMessageService
        , public router: Router
        , public modalService: NgbModal
    ) {
        // Each time the filesSaved observable is emitted,
        // the staged files will be cleared and the fileList control value
        // updates the BehaviorSubject used to populate the Uploaded Files list
        this.filesSaved.subscribe(_ => { 
            this.clearStaged()
            this.fileList$.next(this.formGroup?.controls[this.fileListControl]?.value)
        })
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = GlobalService.setIsMobile(window.innerWidth);
        this.setDropZoneStyles();
    }

    ngOnInit() {
        this.isMobile = GlobalService.setIsMobile(window.innerWidth);
        this.fileCount = this.fileList?.length ?? 0;

        if (this.isV2()) {
            // Each time the fileList$ Behavior Subject is updated, recalculate the file count
            this.fileList$.subscribe(_ => this.fileCount = this.fileList$.getValue()?.length ?? 0);
        }
    }

    isV2 = () : boolean => this.fileList$ != null;

    clearStaged() {
        this.pcgFiles = [];
    }

    ngAfterViewInit() {
        if (this.pcgFiles?.length < this.maxFiles) { this.showDragDrop = true; }
        this.setDropZoneStyles();
        this.cdref.detectChanges();
        if (this.allowedFiles.includes("image")) { this.allowedFiles += this.imgFileTypes; }
    }

    setDropZoneStyles() {
        setTimeout(() => {
            let elem = document.getElementById('divDropZone');
            if (!this.isMobile) {
                elem?.setAttribute("style", `min-width: ${this.minWidthPixels}px; min-height: ${this.minHeightPixels}px;`);
            }
        }, 100);
    }

    uploadFile(file: any, id: string) {
        this.isError = false;
        this.errorString = "";
        let el = file.target.files[0];
        if (el == null) {
            this.errorString = "Unable to upload. Acceptable file types are " + this.allowedFiles;
            return;
        }
        const fileType = el.name.split('.').pop();

        if (!this.allowedFiles.includes(fileType)) {
            this.isError = true;
            this.errorString = "File type " + fileType + " is not allowed. Acceptable file types are " + this.allowedFiles;
            return;
        }
        if (this.allowedFiles.includes("image")) {
            if (!this.imgFileTypes.includes(fileType)) {
                if (!this.allowedFiles.includes(fileType)) {
                    this.isError = true;
                    this.errorString = "File type " + fileType + " is not allowed. Acceptable file types are " + this.allowedFiles;
                    return;
                }
            }
        }

        if (el && el instanceof File) {
            let fileSize = 0;
            const p: PcgFile = new PcgFile();
            const fileReader = new FileReader();
            fileReader.onload = () => {
                p.fileName = el.name;
                p.contentType = el.type || 'application/octet-stream';
                p.contentLength = el.size;
                p.fileContent = fileReader.result as string;
                fileSize = el.size / 1024 / 1024;
            };
            fileReader.readAsDataURL(el);

            setTimeout(() => {
                if (fileSize > 10) {
                    this.isError = true;
                    this.errorString = "File size is too large. 10MB is the maximum."
                } else {
                    this.pcgFiles.push(p);
                    this.formGroup?.controls[this.formGroupControlName]?.setValue(this.pcgFiles);
                    this.fileUploadEvents.emit();
                    this.newFileStaged.emit();
                    this.getDifference();
                }
            }, 300);
        }

        (<HTMLInputElement>document.getElementById(id)).value = "";
    }

    removeAttachment(file: PcgFile) {
        this.pcgFiles = this.pcgFiles.filter(f => f !== file);
        this.getDifference();
        this.formGroup?.controls[this.formGroupControlName]?.setValue(this.pcgFiles);
    }

    filesDropped(files: FileHandle[]): void {
        this.isError = false;
        this.errorString = "";
        this.uploadedFiles = [...this.uploadedFiles, ...files];

        for (let i = 0; i < files.length; ++i) {
            const file = files[i].file;
            if (file && file instanceof File) {
                if (this.pcgFiles.length <= this.maxFiles) {
                    const p: PcgFile = new PcgFile();
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        p.fileName = file.name;
                        p.contentType = file.type || 'application/octet-stream';
                        p.contentLength = file.size;
                        p.fileContent = fileReader.result as string;
                    };
                    fileReader.readAsDataURL(file);
                    this.pcgFiles.push(p);
                }
            }
        }

        setTimeout(() => {
            for (let i = 0; i < this.pcgFiles.length; ++i) {
                const fileName = this.pcgFiles[i].fileName;
                if (fileName == undefined) {
                    this.isError = true;
                    this.errorString = "Unable to upload. Acceptable file types are " + this.allowedFiles;
                    this.pcgFiles.splice(i, 1);
                    return;
                }
                const fileType = fileName.split('.').pop();
                const fileSize = this.pcgFiles[i].contentLength / 1024 / 1024;

                if (fileSize > 10) {
                    this.isError = true;
                    this.errorString = this.pcgFiles[i].fileName + " size is too large. 10MB is the maximum.";
                    this.pcgFiles.splice(i, 1);
                }
                if (!this.allowedFiles.includes(fileType)) {
                    this.isError = true;
                    this.errorString = "File type " + fileType + " is not allowed. Acceptable file types are " + this.allowedFiles;
                    this.pcgFiles.splice(i, 1);
                    return;
                }
                if (this.allowedFiles.includes("image")) {
                    if (!this.imgFileTypes.includes(fileType)) {
                        if (!this.allowedFiles.includes(fileType)) {
                            this.isError = true;
                            this.errorString = "File type " + fileType + " is not allowed. Acceptable file types are " + this.allowedFiles;
                            this.pcgFiles.splice(i, 1);
                        }
                    }
                }
            }
            if (!this.isError) {
                this.getDifference();
                this.formGroup?.controls[this.formGroupControlName]?.setValue(this.pcgFiles);
                this.fileUploadEvents.emit();
            }
        }, 100);
    }

    getDifference() {
        const difference = this.maxFiles - this.pcgFiles.length;
        if (this.pcgFiles?.length < this.maxFiles) { this.showDragDrop = true; }
        if (difference > 0 && this.pcgFiles?.length < this.maxFiles) {
            this.showButtons = true;
            this.setDropZoneStyles();
        } else { this.showButtons = false; }
    }

    delete(fileName : string) {
        if (this.deleteFileUrl != null) {
            this.deleteFile(fileName)
        } else {
            this.deleteFileV2(fileName);
        }
    }

    deleteFile(fileName: string) {
        this.isError = false;
        this.errorString = "";

        if (confirm('Are you sure you want to delete this file?')) {
            this.subscriptions.add(this.sm.getHttpObservable(this,
                `${this.deleteFileUrl}?id=${this.parentId}&fileName=${fileName}`)
                .subscribe((sm: SystemMessage) => {
                    if (sm.isSuccessful) {
                        this.modalService.dismissAll();
                        if (this.navRoute === "") { window.location.reload(); }
                        else if (this.useFullNavRoute === true) {
                            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                this.router.navigateByUrl(this.navRoute);
                            });
                        } else {
                            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                this.router.navigateByUrl(this.navRoute + this.parentId);
                            });
                        }
                    } else {
                        this.isError = true;
                        this.errorString = sm.message;
                    }
                }));
        }
    }

    deleteFileV2(fileName: string) {
        if (!confirm('Are you sure you want to delete this file?')) return;

        const params: HttpParams = new HttpParams()
            .append("id", this.parentId)
            .append("file", fileName)

        this.http.delete<SystemMessage>(this.deleteEndpoint, { params: params })
            .subscribe((sm) => {
                if (!sm.isSuccessful) {
                    this.isError = true;
                    this.errorString = sm.message;
                    return;
                }

                this.modalService.dismissAll();
                this.fileList$.next(sm.model);
            }
        )
    }
}
