import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private toastr: ToastrService) { }
    
    showSuccess(
        message: string
        , override?: Partial<IndividualConfig>
    ) {
        if (
            override === null 
            || override === undefined
        )  {
            override = { 
                timeOut: 0
                , positionClass: 'toast-top-center'
            };
        }
        this.toastr.success(message, "Success", override)
    }
    
    showError(
        message: string
        , override?: Partial<IndividualConfig>
    ) {
        if (
            override === null 
            || override === undefined
        ) {
            override = { 
                timeOut: 0
                , positionClass: 'toast-top-center'
            };
        }
        this.toastr.error(message, "Error", override)
    }
    
    showInfo(
        message: string
        , override?: Partial<IndividualConfig>
    ) {
        if (
            override === null 
            || override === undefined
        ) {
            override = { 
                timeOut: 0
                , positionClass: 'toast-top-center'
            };
        }
        this.toastr.info(message, "Information", override)
    }
    
    showWarning(
        message: string
        , override?: Partial<IndividualConfig>
    ) {
        if (
            override === null 
            || override === undefined
        ) {
            override = { 
                timeOut: 0
                , positionClass: 'toast-top-center'
            };
        }
        this.toastr.warning(message, "Warning", override)
    }
}
