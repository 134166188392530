import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';

export const getHEROSNav: () => NavRoute = () => {
	const user = getUser();
	const isAdmin = user?.moduleAccess?.filter(o => o.module === BusinessAreaEnum.HEROS
		&& o.permissionRole === PermissionRole.SystemAdmin
	)?.length > 0 ?? false;

	return {
		name: 'HEROS'
		, href: 'heros/dashboard'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, children: getHerosNavItems(isAdmin, user)
	};
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}

function getHerosNavItems(isAdmin: boolean, user: UserJsVm) {
	const navItems: NavRoute[] = [];

	navItems.push({
		name: 'Dashboard'
		, exact: true
		, path: 'heros/dashboard'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
	});
		
	navItems.push({
		name: 'Agencies'
		, exact: true
		, path: 'heros/agencies/agency-list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
	});

	navItems.push({
		name: 'Applications'
		, exact: true
		, path: 'heros/applicants/applicant-list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
	});

	navItems.push({
		name: 'Contacts'
		, exact: true
		, path: 'heros/contacts'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, requireAll: true
	});

	var adminChildrenNavRoutes: NavRoute[] = [];
	if (isAdmin) {
		adminChildrenNavRoutes.push({
			name: 'Attachment Types'
			, exact: true
			, path: 'heros/admin/attachment-type'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HEROS ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, requireAll: true
		});
		adminChildrenNavRoutes.push({
			name: 'Fiscal Years'
			, exact: true
			, path: 'administration/fiscal-years'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HEROS ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, requireAll: true
		});
		adminChildrenNavRoutes.push({
			name: 'FY Budget'
			, exact: true
			, path: 'heros/admin/fy-budget'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HEROS ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, requireAll: true
		});
		adminChildrenNavRoutes.push({
			name: 'Misc Accounts'
			, exact: true
			, path: 'heros/admin/misc-accounts'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HEROS ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, requireAll: true
		});
		adminChildrenNavRoutes.push({
			name: 'Ordering Options'
			, exact: true
			, path: 'heros/admin/ordering-options'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HEROS ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, requireAll: true
		});
		adminChildrenNavRoutes.push({
			name: 'Rounds'
			, exact: true
			, path: 'heros/admin/rounds'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HEROS ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
				}
			]
			, requireAll: true
		});
	}

	if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.HEROS)) {
		adminChildrenNavRoutes.push({
			name: 'Error Reporting'
			, exact: true
			, path: `error-report/error-report-list/${BusinessAreaEnum.HEROS}`
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HEROS ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, requireAll: true
		});
	}

	navItems.push({
		name: 'Admin Settings'
		, href: 'administration/fiscal-years'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.HEROS ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, children: adminChildrenNavRoutes
	});

	return navItems;
}
