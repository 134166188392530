import { Component, OnInit, Input, ViewChild, TemplateRef, HostListener, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { faQuestion, faTableList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DateRange } from "app/shared/models/date-range";
import { PcgTableColumn } from "../interfaces/pcg-table-column.interface";
import { ButtonFilterService } from "./button-filter/button-filter.service";
import { GlobalService } from "app/shared/services/global.service";
import { SystemMessage } from "app/core/system-message/system-message-service";
import { param } from "app/shared/http-params";

@Component({
	selector: "pcg-table-top",
	templateUrl: "./table-top.component.html",
	styleUrls: ["./table-top.component.scss"]
})
export class TableTopComponent implements OnInit {

	@ViewChild('tooltipModal', { static: true }) tooltipModal: TemplateRef<any>;

	@Input() exactMatchSource: BehaviorSubject<boolean>;
	@Input() pageSizeSource: BehaviorSubject<number>;	
	@Input() filterSource: BehaviorSubject<string>;
	@Input() pageLengths: [number];
	@Input() columnDefs: Map<string, PcgTableColumn>;
	@Input() canGlobalSearch = true;
	@Input() canExactMatchSearch = true;
	@Input() canShowHide = true;	
	@Input() canShowHideColumns = true;
	@Input() ajaxData: any;
	@Input() table: any;
	@Input() excelUrl: string = null;
	@Input() printUrl: string = null;
	@Input() pdfUrl: string = null;
	@Input() excelTooltip: string = "Export to Excel";
	@Input() printTooltip: string = "Print the Report";
	@Input() pdfTooltip: string = "Export to PDF";	
	@Input() canExportTableToExcel: boolean = false;
	@Input() customExcelFunc: () => void;
	@Input() canPrintTable: boolean = false;
	@Input() canExportTableToPdf: boolean = false;

	@Input() hasTooltips = false;
	@Input() tooltipContent: string;
	@Input() showTilesBtn = false;
	@Input() showTooltipsBtn = false;
	@Input() tableViewStorage = "";	

	// Inputs for implementing the table dropdown filter features
	/** Filter ID to implement table dropdown filters. Passed from table component */
	@Input() filterId: string = null;
	/** Pass through a filtermap to show filters being utilized. Ex. src\app\shared\business-areas\order-list\order-list */
	@Input() filterMap: {} = null;
	/** Filters utilized for the table dropdown filters. Passed from table component */
	@Input() filters: any;
	/** Filter array for special reset button visibility behavior. Passed from table component */
	@Input() emptyFilters: {} = null;
	/** Whether the table dropdown filters have reset capabilities. Passed from table component*/
	@Input() canResetFilters = true;
	/* map of string and and function pointer key value pairs where the key is the key to the filter and the value is a function return a boolean of whether 
	or not to show reset filters*/
	@Input() customResetFiltersMap: Map<string, (args: any) => boolean>;

	@Output() isUsingTiles = new EventEmitter<boolean>();

	subscriptions = new Subscription();
	// Get a unique id to append to the id of controls to make it distinct
	// Doing this to have a distinct id to add to the for attribute of the exact match label
	uniqueId = `${new Date().getDay()}-${new Date().getSeconds()}-${new Date().getMilliseconds()}`;

	faIconName = { faQuestion, faTableList, faTableCellsLarge };

	isMobile: boolean;
	@HostListener("window:resize")
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

	constructor(
		public btnFilterService: ButtonFilterService
		, public modalService: NgbModal
		, private httpClient: HttpClient
	) { }

	ngOnInit() {
		if (this.canResetFilters) { this.emptyFilters ??= { ...this.filters }; }
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
	}

	clearSearch(event) {
		setTimeout(() => { if (event.target.value == "") { this.filterSource.next(""); } }, 1);
	}

	showResetFilterBtn() {
		if (this.customResetFiltersMap != null) {
			return this.canResetFilters 
				&& Object.entries(this.filters).filter(([key, val]) => (
					this.customResetFiltersMap?.has(key) && this.customResetFiltersMap?.get(key)(val)
				))?.length;
		}

		return this.canResetFilters 
			&& Object.entries(this.filters).filter(([key, val]) => (
				key in this.emptyFilters 
				&& val !== false 
				&& !(
					this.emptyFilters[key] === val 
					|| (
						// Checking dateranges due to clearing range sets value to an object.
						this.emptyFilters[key] instanceof DateRange 
							&& this.emptyFilters[key].dateBegin === (val['dateBegin'] ?? null) 
							&& this.emptyFilters[key].dateEnd === (val['dateEnd'] ?? null)
					)
				)
			))?.length;
	}

	print = () => this.printUrl
		? window.open(this.printUrl, "_blank")
		: this.table.print();

	getExcel = () => (this.customExcelFunc != null)
		? this.customExcelFunc.call(this)
		: (this.excelUrl) 
			? this.subscriptions.add(
				this.httpClient.get(this.excelUrl).subscribe((output: any) => { window.open(output); })
			)
			: this.table.getExcel(); 

	getPdf(){
		this.pdfUrl 
			? this.subscriptions.add(
				this.httpClient.get(`${this.pdfUrl}?${param(this.ajaxData)}`).subscribe((output: SystemMessage) => { window.open(output.value); })
			)
			: this.table.getPdf();
	}

	tooltipButtonClicked() { this.modalService.open(this.tooltipModal); }
}
